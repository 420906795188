import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// user option routing

const UserList = Loadable(lazy(() => import('views/admin/user/UserList')));

// ==============================|| USER ROUTING ||============================== //

const UserRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/user/user-list',
      element: <UserList />,
    },
  ],
};

export default UserRoutes;
