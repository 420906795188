export const filterGenerate = async (tableOptions, columnFilters) => {
  const filters = columnFilters.reduce(
    (first, next) => ({ ...first, [next.id]: next.value }),
    {}
  );

  const pagination = {
    skip: tableOptions.pageIndex * tableOptions.pageSize,
    take: tableOptions.pageSize,
  };

  const filterObject = {
    ...pagination,
    ...filters,
  };

  const keys = Object.keys(filterObject);

  const queryString = keys
    .map((key) => `${key}=${filterObject[key]}`)
    .join('&');

  return queryString;
};
