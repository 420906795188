import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// branch option routing

const BranchList = Loadable(
  lazy(() => import('views/admin/branch/BranchList'))
);

// ==============================|| BRANCH ROUTING ||============================== //

const BranchRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/branch/branch-list',
      element: <BranchList />,
    },
  ],
};

export default BranchRoutes;
