import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_START,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from './constants';
import { getAuthUser } from './helpers';

const authUser = getAuthUser();

const initialState = {
  isLoggedIn: !!authUser,
  user: authUser,
  hasError: null,
  isLoading: false,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_START:
      return {
        isLoading: true,
      };
    case REGISTER_SUCCESS:
      return {
        isLoggedIn: true,
        user: payload.user,
        isLoading: false,
      };
    case REGISTER_FAIL:
      return {
        isLoggedIn: false,
        isLoading: false,
      };
    case LOGIN_START:
      return {
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        isLoggedIn: true,
        user: payload,
        isLoading: false,
      };
    case LOGIN_FAIL:
      return {
        isLoggedIn: false,
        isLoading: false,
      };
    case LOGOUT:
      return {
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
