import { USER } from './constants';

export function getAuthUser() {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem(USER));
  }
}

export function getAuthHeader() {
  const user = JSON.parse(localStorage.getItem(USER));

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken };
  } else {
    return {};
  }
}
