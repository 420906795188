import { combineReducers } from 'redux';

// reducers import
import customizationReducer from './customizationReducer';
import authReducer from './auth/reducers';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
});

export default reducer;
