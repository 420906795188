import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// category option routing

const CategoryList = Loadable(
  lazy(() => import('views/admin/category/CategoryList'))
);

// ==============================|| CATEGORY ROUTING ||============================== //

const CategoryRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/category/category-list',
      element: <CategoryList />,
    },
  ],
};

export default CategoryRoutes;
