// assets
import {
  IconKey,
  IconUserCircle,
  IconBuildingCommunity,
  IconTower,
  IconCategory,
  IconAward,
  IconDashboard,
  IconHome,
  IconBusinessplan,
} from '@tabler/icons';

// constants
const icons = {
  IconKey,
  IconUserCircle,
  IconBuildingCommunity,
  IconTower,
  IconCategory,
  IconAward,
  IconDashboard,
  IconHome,
  IconBusinessplan,
};

// ==============================|| Admin ||============================== //

const admin = {
  id: 'admin',
  title: 'Admin',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'admin',
      title: 'Dashboard',
      type: 'item',
      url: '/admin',
      icon: icons.IconHome,
      breadcrumbs: false,
    },
    {
      id: 'business',
      title: 'Business',
      type: 'item',
      url: '/admin/business',
      icon: icons.IconBusinessplan,
      breadcrumbs: false,
    },
    {
      id: 'user',
      title: 'User',
      type: 'item',
      url: '/admin/user/user-list',
      icon: icons.IconUserCircle,
      breadcrumbs: false,
    },
    {
      id: 'category',
      title: 'Category',
      type: 'item',
      url: '/admin/category/category-list',
      icon: icons.IconCategory,
      breadcrumbs: false,
    },
    {
      id: 'branch',
      title: 'Branch',
      type: 'item',
      url: '/admin/branch/branch-list',
      icon: icons.IconBuildingCommunity,
      breadcrumbs: false,
    },
    /* {
      id: 'classroom',
      title: 'Classroom',
      type: 'item',
      url: '/admin/classroom',
      icon: icons.IconBuildingCommunity,
      breadcrumbs: false,
    }, */

    /* {
      id: 'achievement',
      title: 'Achievement',
      type: 'item',
      url: '/admin/achievement/achievement-list',
      icon: icons.IconAward,
      breadcrumbs: false,
    }, */
    {
      id: 'amenity',
      title: 'Amenity',
      type: 'item',
      url: '/admin/amenity/amenity-list',
      icon: icons.IconTower,
      breadcrumbs: false,
    },
  ],
};

export default admin;
