import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// business option routing

const BusinessListPage = Loadable(
  lazy(() => import('views/admin/business/BusinessList.page.js'))
);

const BusinessDetailPage = Loadable(
  lazy(() => import('views/admin/business/BusinessDetail.page'))
);

const BusinessLessonsPage = Loadable(
  lazy(() => import('views/admin/business/BusinessLessons.page'))
);

// ==============================|| BUSINESS ROUTING ||============================== //

const BusinessRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/business',
      element: <BusinessListPage />,
    },
    {
      path: '/admin/business/:businessId',
      element: <BusinessDetailPage />,
    },
    {
      path: '/admin/business/:businessId/classroom/:classroomId',
      element: <BusinessLessonsPage />,
    },
  ],
};

export default BusinessRoutes;
