import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import UserRoutes from './admin/UserRoutes';
import BusinessRoutes from './admin/BusinessRoutes';
import AmenityRoutes from './admin/AmenityRoutes';
import CategoryRoutes from './admin/CategoryRoutes';
import BranchRoutes from './admin/BranchRoutes';
import BusinessOwnerRoutes from './admin/BusinessOwnerRoutes';
import AdminDashboardRoutes from './admin/AdminDashboardRoutes';
import ErrorRoutes from './ErrorRoutes';
// import AchievementRoutes from './admin/AchievementRoutes';
// import ClassroomRoutes from './admin/ClassroomRoutes';

// ==============================|| ROUTING RENDER ||============================== //
const adminRoutes = [
  AdminDashboardRoutes,
  AuthenticationRoutes,
  BusinessOwnerRoutes,
  UserRoutes,
  AmenityRoutes,
  BranchRoutes,
  BusinessRoutes,
  CategoryRoutes,
  ErrorRoutes,
  // AchievementRoutes,
  // ClassroomRoutes,
];

export const Routes = () => {
  return useRoutes(adminRoutes);
};
