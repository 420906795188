// material-ui
import Typography from '@mui/material/Typography';
import i18n from 'i18next';
import * as React from 'react';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return (
    <Typography id="modal-modal-title" variant="h3" component="h2">
      {i18n.t('pages.forBody_admin')}
    </Typography>
  );
};

export default Logo;
