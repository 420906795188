import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login routing
const AuthLogin = Loadable(
  lazy(() => import('views/admin/authentication/authentication/Login'))
);
const AuthRegister = Loadable(
  lazy(() => import('views/admin/authentication/authentication/Register'))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />,
    } /* 
    {
      path: '/register',
      element: <AuthRegister />,
    }, */,
  ],
};

export default AuthenticationRoutes;
