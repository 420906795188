import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// amenity option routing

const AmenityList = Loadable(
  lazy(() => import('views/admin/amenity/AmenityList'))
);

// ==============================|| AMENITY ROUTING ||============================== //

const AmenityRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/amenity/amenity-list',
      element: <AmenityList />,
    },
  ],
};

export default AmenityRoutes;
