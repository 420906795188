import i18n from 'i18next';

export const getProfileSectionTranslation = () => {
  const profile = i18n.t('pages.menu.header.profile');
  const accountSettings = i18n.t('pages.menu.header.account_settings');
  const logout = i18n.t('pages.menu.header.logout');

  return {
    profile,
    accountSettings,
    logout,
  };
};
