import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';

// user option routing

const BusinessRegisterForm = Loadable(
  lazy(() =>
    import(
      'views/admin/business-owner/components/BusinessRegister/BusinessRegisterForm'
    )
  )
);

const BusinessRequestForm = Loadable(
  lazy(() =>
    import(
      'views/admin/business-owner/components/BusinessRequest/BusinessRequestForm'
    )
  )
);
// ==============================|| Business Owner ROUTING ||============================== //

const BusinessOwnerRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/business-owner/:managerId',
      element: <BusinessRegisterForm />,
    },

    {
      path: '/business-owner/:managerId/request',
      element: <BusinessRequestForm />,
    },
  ],
};

export default BusinessOwnerRoutes;
