const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}
    >
      <img src="https://i.imgur.com/qIufhof.png" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 70,
        }}
      >
        <span style={{ fontSize: 150 }}>404</span>
        <h2>This page could not be found</h2>
      </div>
    </div>
  );
};
export default NotFound;
