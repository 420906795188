import { filterGenerate } from 'utils/filter-generate';
import http from 'utils/http-common';

//GET USER SERVICE //
export const getUsers = async (tableOptions, columnFilters) => {
  try {
    const filteredData = await filterGenerate(tableOptions, columnFilters);

    return await http.get(`admin/user?${filteredData}`);
  } catch (error) {
    throw error;
  }
};

// GET USER BY ID SERVICE //
export const getUserById = async (id) => {
  try {
    return await http.get(`admin/user/${id}`);
  } catch (error) {
    throw error;
  }
};

// CREATE USER SERVICE //
export const createUser = async (body) => {
  try {
    return await http.post('admin/user', body);
  } catch (error) {
    throw error;
  }
};

// DELETE USER SERVICE //
export const deleteUser = async (id) => {
  try {
    return await http.delete(`admin/user/${id}`);
  } catch (error) {
    throw error;
  }
};

// USER APPLICATION SERVICE //
export const updateApplication = async (application) => {
  try {
    return await http.patch(`admin/user/application`, application);
  } catch (error) {
    throw error;
  }
};
