// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NotFound from 'ui-component/Error/NotFound';

// error option routing

// ==============================|| ERROR ROUTING ||============================== //

const ErrorRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '*',
      element: <NotFound />,
    },
  ],
};

export default ErrorRoutes;
