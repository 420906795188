import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// AdminDasboard option routing

const AdminDashboard = Loadable(lazy(() => import('views/dashboard/Admin')));

// ==============================|| ADMIN DASHBOARD ROUTING ||============================== //

const AdminDashboardRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin',
      element: <AdminDashboard />,
    },
  ],
};

export default AdminDashboardRoutes;
