import { legacy_createStore as createStore, compose } from 'redux';

import reducer from './reducer';
import middleware from './middleware';
// ==============================|| REDUX - MAIN STORE ||============================== //
const reduxDevTools =
  process.env.NODE_ENV === 'production'
    ? false
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const composeEnhancers = reduxDevTools || compose;
const store = createStore(reducer, composeEnhancers(middleware));
const persister = 'Free';

export { store, persister };
