import { USER } from 'store/auth/constants';
import http from 'utils/http-common';

const login = async ({ email, password }) => {
  try {
    const res = await http.post('admin/auth/login', { email, password });
    return res;
  } catch (error) {
    throw error;
  }
};

// REGISTER SERVICE //
const register = async (body) => {
  try {
    return await http.post('admin/auth/register', body);
  } catch (error) {
    throw error;
  }
};

const logout = () => {
  localStorage.removeItem(USER);
};

const auth = {
  login,
  logout,
  register,
};
export default auth;
