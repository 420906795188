import { LOCAL_STORAGE_KEY_CONSTANTS } from 'utils/constants/local-storage';
import i18n from '../../i18n';

export const LanguageSwitcher = () => {
  const changeLanguage = (language) => {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_CONSTANTS.FORBODY_ADMIN_LANGUAGE_KEY,
      language
    );
    i18n.changeLanguage(language);
    window.location.reload();
  };

  const languages = [
    { code: `en`, translateKey: `english` },
    { code: `tr`, translateKey: `turkish` },
  ];

  return (
    <>
      <div>
        {languages.map((language) => (
          <button
            style={{ margin: `2px`, cursor: `pointer` }}
            onClick={() => changeLanguage(language.code)}
            key={language.code}
          >
            {i18n.t(
              language.translateKey === `english`
                ? `EN`
                : language.translateKey === `turkish`
                ? `TR`
                : null
            )}
          </button>
        ))}
      </div>
    </>
  );
};
